//$background-color: #202020;
$background-color: #270722;
$navbar-transparent: rgba(255, 255, 255, 0);
$pale-gold: #ECCE8E;
$lightpale-gold: #DBCF96;
$dark-vanilla: #C2C6A7;
$pale-cerulean: #9AC2C5;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

html,
body,
#app,
#app > div,
#root {
  background-color: $background-color;
  color: black;
}

.nav-link:focus {outline:none  ;}

.nav-link:hover {
  color: dodgerblue !important;
  cursor: pointer;
  text-decoration: darkgray;
}

.navbar {
  background-color: $navbar-transparent;
  //backdrop-filter: blur(10px);
}

.navbar-collapse.collapse.show {
  background-color: rgba(0,0,0,0);
  backdrop-filter: blur(7px);
  border-radius: 10px;
  margin: 0
}

.about-contents {
  font-size: 300%;
}

.landing-header {
  font-size: 75px;
  padding-left: 10%;
  padding-right: 10%;
}

.landing-subheader {
  font-size: 35px;
}

.contact-icon {
  font-size: 10vw;
  margin: 0 2vw;
}

// desktop
@media  (max-width: 1920px) {
  .about-contents {
    font-size: 200%;
  }

  .landing-header {
    font-size: 75px;
    padding-left: 10%;
    padding-right: 10%;
  }

  .landing-subheader {
    font-size: 35px;
  }

  .contact-icon {
    font-size: 10vw;
    margin: 0 2vw;
  }
}

@media only screen and (max-width: 1366px) {
  .about-contents h1 {
    font-size: 150%;
  }

  .about-contents {
    font-size: 150%;
  }

  .landing-header {
    font-size: 60px;
  }

  .landing-subheader {
    font-size: 30px;
  }

  .contact-icon {
    font-size: 10vw;
    margin: 0 2vw;
  }
}

@media (max-width: 768px) {
  .about-contents {
    font-size: 100%;
  }

  .landing-header {
    font-size: 40px;
  }

  .landing-subheader {
    font-size: 25px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .github-nav {
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .contact-icon {
    font-size: 5vh !important;
    margin: 4vw 4vw !important;
  }
}


@media (max-width: 414px) {
  .nav-link {
    color: blue;
    font-size: 1px;
  }
}
